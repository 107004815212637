import { render, staticRenderFns } from "./actions.vue?vue&type=template&id=42f3ccbc&scoped=true&"
import script from "./actions.js?vue&type=script&lang=js&"
export * from "./actions.js?vue&type=script&lang=js&"
import style0 from "./actions.css?vue&type=style&index=0&id=42f3ccbc&scoped=true&lang=css&"
import style1 from "./actions_vuetify.css?vue&type=style&index=1&lang=css&"
import style2 from "./actions_swiper.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f3ccbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDialog,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemTitle,VSwitch})
