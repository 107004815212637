import Scenarios from 'app/home/components/section/scenarios/scenarios.vue'
import GroupResource from 'common/services/resources/group.js'
import CordovaService from 'common/services/cordova.js'
import ClMenu from 'common/components/menu/menu.vue'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
import ArrayService from 'common/services/array.js'
import CommonResource from 'common/services/resources/common.js'
import DeviceResource from 'common/services/resources/device.js'
import ScenarioResource from 'common/services/resources/scenario.js'
import LocationResource from 'common/services/resources/location.js'
import StorageService from 'common/services/storage.js'
import PhoneNumberService from 'common/services/phone-number.js'
import DeviceInstall from 'app/home/components/section/add-device/add-device.vue'
import ServiceAdd from 'app/home/components/section/add-service/add-service.vue'

export default {
	components: {DeviceInstall, ServiceAdd, ClMenu, Scenarios, DeleteModal},
	props:
	{
		config: {
			type: Object,
			default:{
				sectionName: 'actions'
			}
		},
		plant: {
			type: Object
		},
		devices: {
			type: Array
		},
		subDevices: {
			type: Array
		},
		scenarios: {
			type: Array
		},
		services: {
			type: Array
		},
		systems: {
			type: Array
		},
		models: {
			type: Array
		},
		homepilots: {
			type: Array
		},	
		brands: {
			type: Array
		},
		brandGroups: {
			type: Array
		},	
		icons: {
			type: Array
		},		
		defaultRooms: {
			type: Array
		},
		externalAccounts: {
			type: Object
		},
		displayScenariosModal: {
			type: Boolean,
			default: false
		},
		appConstants: {
			type: Object
		},
		displayInstallModal: {
			type: Boolean,
			default: false
		},
		displayServiceAddModal: {
			type: Boolean,
			default: false
		},
		serviceModels: {
			type: Array,
			default: []
		},
		user: {
			type: Object
		},
		versionInfos:{
			type: Object,
			default: {}
		},
		capabilities:{
			type: Object,
			default: {}
		},
		devicesByCapability:{
			type: Array,
			default: []
		}
	},
  data:
		function () {
			return {
					CONSTANTS:
					{
						SCENARIO_TRIGGER_SUCCESS : this.$gettext('The scenario "%{s}" launched'),
						SCENARIO_TRIGGER_ERROR : this.$gettext('Unable to trigger the scenario'),
						SCENARIO_ENABLE_SUCCESS : this.$gettext('The scenario has been successfully enabled'),
						SCENARIO_ENABLE_ERROR : this.$gettext('Unable to enable the scenario'),
						SCENARIO_DISABLE_SUCCESS : this.$gettext('The scenario has been successfully disabled'),
						SCENARIO_DISABLE_ERROR : this.$gettext('Unable to disable the scenario'),
						DELETE_SCENARIO_ERROR : this.$gettext('Unable to delete the scenario'),
						DELETE_SCENARIO_SUCCESS : this.$gettext('The scenario has been successfully deleted'),
						DELETE_GROUP_ERROR : this.$gettext('Unable to delete the equipment group'),
						DELETE_GROUP_SUCCESS : this.$gettext('The equipment group has been successfully deleted'),
						EDIT_SERVICE_ERROR : this.$gettext('Unable to edit the kit'),
						EDIT_SERVICE_SUCCESS : this.$gettext('The kit has been successfully edited'),
						DELETE_SERVICE_ERROR : this.$gettext('Unable to delete the kit'),
						DELETE_SERVICE_SUCCESS : this.$gettext('The kit has been successfully deleted'),
											GROUP_TXT : this.$gettext('add a new group'),
						SCENARIO_SUBTITLE : {
						'trigger': _upperFirst(this.$gettext('trigger(s)')),
						'action': _upperFirst(this.$gettext('action(s)'))
											},
						SERVICE_SUBTITLE : {
						'detectors': _upperFirst(this.$gettext('detector(s)')),
						'alerts': _upperFirst(this.$gettext('alert(s)')),
						'trigger': this.$gettext('Triggered on detect'),
						'on': _upperFirst(this.$gettext('on')),
						'off': _upperFirst(this.$gettext('off'))
						},
						PUSH : _upperFirst(this.$gettext('notification')),
						PUSH_TRIGGER : this.$gettext('Display a notification'),
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					servicesAlarm:[],
					servicesHeating:[],
					servicesAccessControl:[],
					editedService:undefined,
					scenariosModal:false,
					closeConfirmDialog: false,
					actionsSwiperOption: {
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						},
						mousewheel: true,
						slidesPerView: 2
					},
					editScenario:{
						scenario:{},
						index:0
					},
					selected:{
						name:'',
						id:'',
						category:'',
						title:''
					},
					deleteModal: false,
					modalActionsDeleteConfirm: '',
					titleScenario: 'Create a scenario',
					homepilot:null,
					brandsName:undefined,
					availableAddDeviceModels:undefined,
					installModal:false,
					displayCloseIconInstallModal:true,
					blockClick:true


			}
	},

	created: function () {
		this.eventHub.addEvent('scenariosDialogValidate', this.scenariosDialogValidate);
		this.eventHub.addEvent('scenariosDialogEdit', this.scenariosDialogEdit);
		this.eventHub.addEvent('addScenario', this.addScenario);
		this.$nextTick(function() {
			this.initSwiper();
		});
	},

	mounted: function () {
		this.scenariosModal = this.displayScenariosModal;
		this.installModal = this.displayInstallModal;
	},
	beforeDestroy: function ()
	{
	},
	computed: {

		computedServices: function () {
			return _orderBy(_filter(this.services, (o) => {return this.plant.id==o.plantId; }), function(s) { return s.name.toLowerCase()});
		},
	},
  	methods:
  	{
		initSwiper : function()
		{
			this.$refs['actionsSwiper'].$swiper.params.slidesPerView = (window.innerWidth<=768 ? 1 : 2);
			this.$refs['actionsSwiper'].$swiper.update();
		},
		
		openDeleteModal : function(id, name, category){
			this.deleteModal = true;
			this.selected.name = name;
			this.selected.id = id;
			this.selected.category = category;
			if(category == 'scenario'){
				this.modalActionsDeleteConfirm = this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the scenario \'%{s}\'?'), {s: this.selected.name});
				this.selected.title = this.$gettext('Delete the scenario');
			}
			else if(category == 'service'){
				this.modalActionsDeleteConfirm = this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the kit \'%{s}\'?'), {s: this.selected.name});
				this.selected.title = this.$gettext('Delete the kit');
			}
		},

		deleteAction : function(){
			if(this.selected.category == 'scenario')
				this.deleteScenario();
			else if(this.selected.category == 'service')
				this.deleteService();
		},


    	//SCENARIOS

		addScenario: function(){
			this.editScenario.scenario = {};
			this.editScenario.index = 0;
			this.titleScenario = 'Create a scenario';
			this.scenariosModal=true;
		},
		deleteScenario : function(){
			var _this = this;
			LocationResource.deleteLocationTopology('module', this.selected.id).then(
			function() {
						var index = _findIndex(_this.scenarios, function(o) { return o.id == _this.selected.id });
						_this.scenarios.splice(index, 1);
						for(var i=0; i<_this.plant.zones.length; i++){
							if(_findIndex(_this.plant.zones[i].modules, function(o) { return o.id == _this.selected.id }) != -1){
								var index2 = _findIndex(_this.plant.zones[i].modules, function(o) { return o.id == _this.selected.id });
								_this.plant.zones[i].modules.splice(index2, 1);
								break;
							}
						}
						_this.eventHub.$emit('setLoading',false);
						_this.deleteModal = false;
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_SCENARIO_SUCCESS, 'success');
			}).catch(
			function(e) {
						if(e == 'Error: Network Error')
							_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
						else if(e.response && e.response.status == 401)
							_this.eventHub.$emit('logout');
						else
							_this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_SCENARIO_ERROR);
						_this.eventHub.$emit('setLoading',false);
			});
		},
		openEditScenario : function(scenario, index){
			if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
			{
			  return false;
			}
			this.editScenario.scenario = scenario;
			this.editScenario.scenario.actions = this.getScenarioActionTrigger(scenario.scenario.actions);
			this.editScenario.scenario.triggers = this.getScenarioActionTrigger(scenario.scenario.triggers);
			this.editScenario.index = index;
			this.editScenario.scenario.locationId = this.roomScenario(scenario.id);
			this.titleScenario = 'Edit a scenario';
			this.scenariosModal=true;
		},
		roomScenario : function(id){
			for(var i=0; i<this.plant.zones.length; i++){
				if(_find(this.plant.zones[i].modules, function(o) { return o.id == id; }))
					return this.plant.zones[i].id;
			}
		},
		getScenarioActionTrigger(aORt){
			if(aORt){
				for(var i=0; i<aORt.length; i++){
					delete aORt[i].id;
					delete aORt[i].infos.type;
					if(aORt[i].infos.id)
						aORt[i].infos.moduleId = aORt[i].infos.id;
					}
					return aORt;
			}
		},

		triggerScenario: function(s){
			this.eventHub.$emit('displayAlert', this.$gettextInterpolate(this.$gettext(this.CONSTANTS['SCENARIO_TRIGGER_SUCCESS']), {s: s.name}), 'info');
			ScenarioResource.trigger(s, this.eventHub);
		},		
		scenariosDialogValidate: function(scenario){
			if(scenario){
				scenario.state = 'enable';
				this.scenarios.push(scenario);
			}
			this.closeConfirmDialog=false;
			this.scenariosModal=false;
			this.eventHub.$emit('setLoading',false);
		},
		scenariosDialogEdit: function(){
			this.scenariosModal=false;
			this.eventHub.$emit('getAllTopology', 'all');
		},

		activateScenario: function(s){
			var _this = this;
			var commandId = this.uuidV4();
			//s.scenario.status[0].value = (s.scenario.status[0].value == 'enable' ? 'disable' : 'enable');
			var json = {
				"status": [
					{
					"capability": "active",
					"value": s.scenario.status[0].value
					}
				],
				"commandId": commandId
				}
			ScenarioResource.status(json, s.id).then(
				function() {
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS['SCENARIO_'+s.scenario.status[0].value.toUpperCase()+'_SUCCESS'], 'success');
				}).catch(
				function(e) {
					if(e.response.status == 401)
						_this.eventHub.$emit('logout');
					else
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS['SCENARIO_'+s.scenario.status[0].value.toUpperCase()+'_ERROR']);
			});
		},

		//SERVICES

		openEditService : function(s){
			if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
			{
			  return false;
			}
			let editedService = JSON.parse(JSON.stringify(s));
			this.eventHub.$emit('openEditService', editedService);
		},

		setServiceStatus : function(s,status){
			if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
			{
			  return false;
			}
			let c = _find(s.service.status, {'capability':status.capability});
            c.value = status.value;
			CommonResource.setStatusService(status, s.id, s.service.model, s, this.eventHub);
        },

		changeClass : function(s,status)
		{
			let classObj = {};

			let c = _find(s.service.status, {'capability':status.capability});
			if(c)
			{
				classObj = 
				{
					unselected : (status.value != c.value)
				}
			}

			return classObj;
		},

		inputActivate : function(s)
		{
			let c = _find(s.service.status, {'capability':'active'});
			if(c)
			{
				return c.value;
			}

			return 'disable';
		},

		changeActivate : function(s)
		{
			let c = _find(s.service.status, {'capability':'active'});
			if(c)
			{
				c.value = (c.value=='enable'?'disable':'enable');
			}
			this.setServiceStatus(s,c)
		},

		activeOpacity: function(module){
			let c = _find(module.status, {'capability':'active'});
			if(c)
			{
				if(c.value == 'off' || c.value == 'disable')
				{
					return 'color:rgba(125,125,125,0.8) !important;';
				}
			}
			return ''
		},
  }
}
